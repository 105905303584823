import React from 'react';
import { useCookies } from 'react-cookie';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TranslateIcon from '@material-ui/icons/Translate';

import { useStyles } from './styles';

const ToggleTranslation = () => {
	const [cookies, setCookie] = useCookies();
	const enabled = cookies['phraseEnabled'] === 'true';
	const [showSwitch, setShowSwitch] = React.useState(true);

	const classes = useStyles({ showSwitch });

	const onHandleChange = React.useCallback(() => {
		setCookie('phraseEnabled', enabled ? 'false' : 'true');
		window.location.reload();
	}, [enabled, setCookie]);

	const onHandleShowChange = React.useCallback(() => {
		setShowSwitch(show => !show);
	}, []);

	return (
		<Paper className={classes.toggleButtonContainer} elevation={3}>
			<div id="toggleTranslationSwitchContainer" className={classes.switchContainer} data-displayed={showSwitch}>
				<FormGroup row>
					<FormControlLabel
						classes={{ root: classes.switchLabel }}
						control={<Switch id="toggleTranslationSwitch" checked={enabled} onChange={onHandleChange} name="translate" />}
						label="Translate"
					/>
				</FormGroup>
			</div>
			<IconButton id="toggleTranslationBtn" color="primary" onClick={onHandleShowChange}>
				{showSwitch ? <ArrowBackIcon /> : <TranslateIcon />}
			</IconButton>
		</Paper>
	);
};

export default React.memo(ToggleTranslation);
